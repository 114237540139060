import ViewDocument from '../components/Document/ViewDocument'
import DocumentActions from '../components/Document/DocumentActions'

export default function Document() {
    return (
        <>
            <ViewDocument />
            <DocumentActions />
        </>
        
    );
}