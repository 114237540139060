import React, { useContext, useEffect } from 'react';
import { Outlet } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { NavLink } from 'react-router-dom';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';

import logo from '../img/FIA_Device.svg'; // Tell Webpack this JS file uses this image

export default function Root() {
    const { setToken } = useContext(AuthContext);

    const logout = () => {
        setToken("");
    }

    useEffect(() => {
        serviceWorkerRegistration.notify();
    });

    let activeClassName = "invisible";

    return (
        <>
            <header className="toolbar">
                <div className="back">
                    <NavLink
                        to="/"
                        end
                        className={({ isActive }) =>
                            isActive ? activeClassName : undefined
                        }
                    >
                        ←
                    </NavLink>
                </div>                
                <img src={logo} className="logo" />
                <div onClick={ logout }>Sign out</div>
            </header>
            <div id="detail">
                <Outlet />
            </div>
        </>
    );
}