import React, { useContext } from 'react';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/root";
import ErrorPage from "./error-page";
import Documents from "./routes/documents";
import Document from "./routes/document"
import Login from './components/Login/Login';
import { AuthContext } from "./context/AuthContext";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import "./bootstrap.min.css"
import "./F1.css";


const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <Documents />,
            },
            {
                path: "documents/:masterDocumentLogId",
                element: <Document />,
            },
        ],

    },
]);

export default function App() {
    const { token, setToken } = useContext(AuthContext);

    if(!token) {
        return <Login setToken={setToken} />
    }

    return (
        <RouterProvider router={router} />
    );
}
