
export const documentAPI = {
    getDocs: () => {
        return fetch('https://' + process.env.REACT_APP_API_URL + 'documentsv2', { credentials: 'include' })
            .then((response) => response.json())
            .then((jsonResponse) => {
                if (jsonResponse.statusCode && jsonResponse.statusCode === 401) {
                    documentAPI.handleUnauth();
                    return null;
                }
                return jsonResponse.data;
            })            
    },
    forwardDocument: (data) => {
        return fetch(process.env.REACT_APP_URL + 'document/forward', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
    },

    handleUnauth: () => { }
}