import React, { useState, useContext, useEffect } from 'react';
import Document from '../Document/documentSummary'
import { AuthContext } from "../../context/AuthContext";


export default function DocumentList() {
    const [docs, setDocs] = useState();
    const { contextAPI, documentWebSocket } = useContext(AuthContext);
    const [docsQueue, setdocsQueue] = useState();

    useEffect(() => {
        let apiTimeout = setTimeout(fetchAPIData, 0);

        function fetchAPIData() {
            contextAPI.getDocs()
                .then((docs) => {
                    if (docs !== null) {
                        setDocs(docs);                        
                    }
                    apiTimeout = setTimeout(fetchAPIData, 60000);
                    setdocsQueue(apiTimeout);
                })
        }
    }, [contextAPI])    

    documentWebSocket.onmessage = function (event) {
        contextAPI.getDocs()
            .then((docs) => {
                if (docs !== null)
                    setDocs(docs);
            });
    };

    const handleManualFetch = async e => {

        contextAPI.getDocs()
            .then((docs) => {
                if (docs !== null) {
                    setDocs(docs);
                }
            })
    }

    return (
        <>
            <div id="documents-panel">
                <div id="docGrid">
                    <div className="doc-grid-container">
                        {docs && docs.map(doc =>
                            <div className="doc-row group-2" key={doc.masterDocumentLogId}>
                                <Document document={doc} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="document-toolbar">   
                <ul class="list-unstyled">
                    <li>
                        <a href="#" class="doc-meta-email" onClick={ handleManualFetch }>
                            <i class="fa fa-spinner"></i> Fetch new Documents
                        </a>
                    </li>
                </ul>          
            </div>
        </>
    );
    
}