import React, { useState } from 'react';
import PropTypes from 'prop-types';

import "./login.scss";

async function loginUser(credentials) {
    return fetch('https://' + process.env.REACT_APP_API_URL + 'auth/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(credentials),
    })
    .then((response) => response.json())

}

export default function Login({ setToken }) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [systemMessage, setSystemMessage] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        let promise = Notification.requestPermission().then(
            async (permissionLevel) => {
                if (permissionLevel == "granted") {
                    const loginResponse = await loginUser({
                        username,
                        password
                    });
                    if (loginResponse.success) {
                        setToken(loginResponse.user.userName);
                    }
                    else {
                        setSystemMessage(loginResponse.error)
                    }
                } else {
                    //some note here about having to turn on notifications to log in
                }
            }
        );
        
    }

    return (
        <div className="login-container">
            <div className="logo logo-large"></div>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label className="field-validation-error">{systemMessage}</label>
                </div>
                <div className="form-group">
                    <label htmlFor="LoginName">Username</label>
                    <input type="text" className="form-control" id="LoginName" name="LoginName" onChange={e => setUserName(e.target.value)} />                    
                </div>
                <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" className="form-control" id="password" name="password" onChange={e => setPassword(e.target.value)} />                    
                </div>
                <input type="submit" className="btn btn-block btn-fia" value="Login" />
            </form>
        </div>
    );
}


Login.propTypes = {
    setToken: PropTypes.func.isRequired
}