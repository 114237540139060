import React, { useContext, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { AuthContext } from "../../context/AuthContext";

export default function DocumentActions() {
    const [searchParams] = useSearchParams();
    let docId = searchParams.get('documentId');
    let { masterDocumentLogId } = useParams();
    const [emailSendState, setEmailSendState] = useState("");
    const { contextAPI } = useContext(AuthContext);

    const handleSendEmail = async e => {

        var data = {
            MasterDocumentLogId: masterDocumentLogId,
            DocumentId: docId
        }

        setEmailSendState("fa-send");

        contextAPI.forwardDocument(data)
            .then((data) => {
                if (data.Success) {
                    setEmailSendState("fa-check");
                }                    
                else {
                    setEmailSendState("fa-remove");
                }
            });
    }


    return (
        <div className="document-toolbar">   
            <ul class="list-unstyled">
                <li>
                    <a href="#" class="doc-meta-email" onClick={handleSendEmail}>
                        <i class="fa fa-envelope"></i> Email Document <i className={"fa " + emailSendState}></i>
                    </a>
                </li>
            </ul>          
        </div>
    );

}