import React, { createContext } from "react";
import { documentAPI as docAPI } from "../API/DocumentAPI";
import useToken from '../Hooks/useToken';

export const AuthContext = createContext();

const AuthContextProvider = props => {
    const { token, setToken } = useToken();

    var myDocAPI = docAPI;
    myDocAPI.handleUnauth = function() {
        setToken("");
    }

    const contextAPI = myDocAPI;

    const startWS = () => {
        var url = 'wss://' + process.env.REACT_APP_API_URL +  "websocket/start";
        const ws = new WebSocket(url);

        ws.onmessage = function (e) {
            console.log("" + e.data);
        };

        return ws;
    }

    const documentWebSocket = startWS();

    return (
        <AuthContext.Provider value={{ contextAPI, documentWebSocket, token, setToken }}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;