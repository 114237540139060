import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

export default function DocumentSummary(props) {
    const doc = props.document;

    var docClass = classNames({
        doc: true,
        'unread': doc.highlightDocument,
        'recalled': doc.recalled
    });


    return (
        <div className="doc-row group-2">
            <Link to={`documents/${doc.masterDocumentLogId}?documentId=${doc.documentId}`} className={docClass}>
                <div className="doc-details">
                    <div className="doc-sender">{doc.createdBy}</div>
                    <div className="doc-subject">{doc.documentName}</div>
                </div>
                <div className="doc-info">
                    <div className="doc-date">
                        {new Intl.DateTimeFormat('en-GB', {
                            month: '2-digit',
                            day: '2-digit',
                            year: 'numeric',
                        }).format(new Date(doc.dateCreated))}
                        <br />
                        {new Intl.DateTimeFormat('default', {
                            hour: "numeric",
                            minute: "numeric",
                            pattern: "{hour}:{minute}",
                            timeZone: "UTC",
                            hour12: false,
                        }).format(new Date(doc.dateCreated))}
                    </div>
                    <div className="doc-number"> #{doc.documentNo}</div>
                </div>
                
                
            </Link>
        </div>
    );

}