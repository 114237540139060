import React, { useState, useEffect, useContext } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { AuthContext } from "../../context/AuthContext";

import "./document.scss";

export default function ViewDocument() {
    const [docMarkup, setMarkup] = useState();
    const [fetchFailed, setfetchFailed] = useState(false);
    const { token } = useContext(AuthContext);
    const [searchParams] = useSearchParams();
    let docId = searchParams.get('documentId');
    let { masterDocumentLogId } = useParams();

    useEffect(() => {
        var data = {
            MasterDocumentLogId: masterDocumentLogId,
            DocumentId: docId,
            userName: token
        }

        let url = process.env.REACT_APP_URL + 'frontend/viewdocument'
            + '?' + (new URLSearchParams(data)).toString();

        fetch(url, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.text())
            .then((doc) => {
                setMarkup({ __html: doc });
            })
            .catch((error) => {
                //if error is 403 unauthorized then remove state of 'loggedin'

                //oh you're not logged in you got a 403 so lets log you out
                setfetchFailed(true)
                console.error('Error:', error);
            });
    }, [docId, masterDocumentLogId])

    return (
        <div className="doc-row group-2">   
            {fetchFailed ?
                <div class="fail-message"><div>Could not fetch document Data, Are you offline?</div></div>
                :
                <div dangerouslySetInnerHTML={docMarkup}></div>                
            }            
        </div>
    );

}